import React, {
	Fragment,
	useCallback,
	useEffect,
	useMemo,
	useReducer,
	useState,
} from 'react';
import {
	CheckCircleTwoTone,
	ExclamationCircleTwoTone,
	FileImageOutlined,
	FileOutlined,
	PauseCircleTwoTone,
	SoundOutlined,
	UploadOutlined,
	VideoCameraOutlined,
} from '@ant-design/icons';
import {
	Button,
	Col,
	Drawer,
	message,
	Progress,
	Row,
	Space,
	Tooltip,
	Typography,
	Upload as AntUpload,
} from 'antd';
import {
	ROLE_PROJECT_MANAGER,
	UPLOAD_PROCESS_STATUS_CODES,
	UPLOAD_STATES,
} from './../../../Constants';
import { useDispatch, useSelector } from 'react-redux';
import {
	deleteFileFromBucket,
	getAcceptedExtensions,
	getFileURL,
	removeFileEntry,
	setAttachedFileInfo,
	setMinFiles,
} from '../../../store/uploadSlice';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useMediaQuery } from 'react-responsive';
import ErrorAlert from '../../../components/Error/ErrorAlert';
import { UploadTagAttrsSchema } from './schema';

import './Upload.scss';
import { get } from 'lodash-es';
import PdfViewer from '../FilePreview/PdfViewer';
import HtmlViewer from '../FilePreview/HtmlViewer';
import TextViewer from '../FilePreview/TextViewer';
import EmlViewer from '../FilePreview/EmlViewer';
import AudioViewer from '../FilePreview/AudioViewer';
import VideoViewer from '../FilePreview/VideoViewer';
import OthersViewer from '../FilePreview/OthersViewer';
import ImageViewer from '../FilePreview/ImageViewer';

const { Text } = Typography;

const initialExtenstions = [];

const fileExtListReducer = (state, action) => {
	switch (action.type) {
		case 'setExtensions':
			return action.payload;
		default:
			return state;
	}
};

const Upload = ({ attrs }) => {
	const { user } = useAuthContext();
	const {
		filetype,
		maxsize,
		minsize,
		minfiles,
		maxfiles,
		name,
		minsnr,
		maxsnr,
		loudness,
		truepeak,
		handlePlay,
	} = attrs;

	const dispatch = useDispatch();
	const {
		toUploadCompletionFiles,
		activeCompletionID,
		acceptedExtensions,
		acceptedExtensionsError,
	} = useSelector((state) => state.upload);

	const attachedFiles = useMemo(() => {
		return (
			Object.values(
				toUploadCompletionFiles[activeCompletionID]?.files ?? {},
			).filter(({ tagId }) => tagId === name) ?? []
		);
	}, [toUploadCompletionFiles, name, activeCompletionID]);

	const [fileExtList, updateFileExtList] = useReducer(
		fileExtListReducer,
		initialExtenstions,
	);

	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const [fileData, setFileData] = useState(null);
	const [isUploadBtnDisabled, setIsUploadBtnDisabled] = useState(false);

	const isMobile = useMediaQuery({ maxWidth: 480 });
	const isTablet = useMediaQuery({ maxWidth: 1224 });

	const minFileCount = useMemo(() => (!minfiles ? 1 : minfiles), [minfiles]);
	const maxFileCount = useMemo(
		() => (!maxfiles && !minfiles ? 1 : minfiles && !maxfiles ? '' : maxfiles),
		[maxfiles, minfiles],
	);

	const minFileSize = useMemo(() => (!minsize ? 0.001 : minsize), [minsize]);
	const maxFileSize = useMemo(
		() => (!maxsize && !minsize ? 2097152 : minsize && !maxsize ? '' : maxsize),
		[maxsize, minsize],
	);

	const showDrawer = useCallback((data) => {
		setFileData(data);
		setIsDrawerOpen(true);
	}, []);

	const handleOnClose = useCallback(() => {
		setIsDrawerOpen(false);
		setFileData(null);
	}, []);

	useEffect(() => {
		getAcptdExtensions(filetype);
		// eslint-disable-next-line
	}, [filetype, acceptedExtensions]);

	useEffect(() => {
		if (activeCompletionID)
			dispatch(setMinFiles({ minFileCount, tagId: name }));
	}, [activeCompletionID, minFileCount, name, dispatch]);

	useEffect(() => {
		if (attachedFiles?.length === parseInt(maxFileCount))
			setIsUploadBtnDisabled(true);
		else setIsUploadBtnDisabled(false);
	}, [
		attachedFiles,
		toUploadCompletionFiles,
		activeCompletionID,
		user,
		setIsUploadBtnDisabled,
		maxFileCount,
	]);

	useEffect(() => {
		if (acceptedExtensions?.length === 0) dispatch(getAcceptedExtensions());
		// eslint-disable-next-line
	}, []);

	const validationAttributes = {
		maxSize: maxFileSize,
		minSize: minFileSize,
		minFiles: minFileCount,
		maxFiles: maxFileCount,
		minSnr: minsnr,
		maxSnr: maxsnr,
		loudness,
		truepeak,
	};

	const hasFiles = !!attachedFiles.length;
	const isAudioExtensionsAllowed = fileExtList?.some((ext) =>
		get(acceptedExtensions, 'audio')?.includes?.(ext),
	);
	const audioValidationAttrsUsed = Boolean(
		minsnr || maxsnr || loudness || truepeak,
	);

	let validationResult;

	if (hasFiles && !isAudioExtensionsAllowed && audioValidationAttrsUsed) {
		validationResult = {
			isValid: false,
			errors: [
				"'minSnr', 'maxSnr', 'loudness', and 'truepeak' are only accepted when audio files are allowed",
			],
		};
	} else {
		validationResult = UploadTagAttrsSchema.validateSafe(validationAttributes, {
			aboutEarly: true,
		});
	}

	const hasValidationError = !validationResult.isValid;

	const getAcptdExtensions = (fileType) => {
		let allExtenstions = [];

		if (fileType && fileType !== '*') {
			const ft = [...new Set(fileType.split(','))].map((td) => {
				const [type, ext] = td?.split(':');
				if (!ext) {
					return acceptedExtensions[type];
				}
				const extList = ext?.split('+');
				return (
					acceptedExtensions[type] &&
					acceptedExtensions[type].filter((k) => extList.includes(k))
				);
			});

			allExtenstions = ft.flat();

			updateFileExtList({
				type: 'setExtensions',
				payload: allExtenstions,
			});
		} else {
			allExtenstions = Object.values(acceptedExtensions).flat();
			updateFileExtList({
				type: 'setExtensions',
				payload: allExtenstions,
			});
		}
	};

	const getFileExtension = useCallback(
		(file) => file.substring(file.lastIndexOf('.') + 1, file.length),
		[],
	);

	const handleOnPreview = useCallback(
		async (file) => {
			let url = '';
			if (file.fileId) {
				await dispatch(getFileURL(file.fileId)).then((result) => {
					url = !result.error ? result.payload : undefined;
					showDrawer({
						url: url,
						type: file.type,
						file,
						error: result.error && result.payload,
					});
				});
			} else {
				showDrawer({
					type: file.type,
					url: url,
					file,
				});
			}
		},
		[showDrawer, dispatch],
	);

	const handleBeforeUpload = useCallback(
		(file) => {
			const fileSize = file.size / 1024;
			const fileExtension = getFileExtension(file.name.toLowerCase());
			const isValidType = fileExtList.includes(fileExtension);
			const isValidSize =
				fileSize <= parseFloat(maxFileSize) &&
				fileSize >= parseFloat(minFileSize);

			if (!isValidType) {
				message.error(`${file.name} has invalid file extension!`);
			}

			if (!isValidSize) {
				message.error(`${file.name} has invalid file size!`);
			}

			return isValidType && isValidSize ? false : AntUpload.LIST_IGNORE;
		},
		[fileExtList, getFileExtension, maxFileSize, minFileSize],
	);

	const handleOnChange = useCallback(
		({ fileList }) => {
			dispatch(setAttachedFileInfo({ fileList, name }));
		},
		[dispatch, name],
	);

	const handleDeleteMsg = (result, file) => {
		if (result.meta.requestStatus === 'fulfilled')
			message.success(`${file.filename} has been deleted successfully`);
		else message.error(`Failed to delete ${file.filename}`);
	};

	const handleOnDelete = (file) => {
		if (file.fileId) {
			dispatch(deleteFileFromBucket(file.fileId)).then((result) =>
				handleDeleteMsg(result, file),
			);
		} else {
			dispatch(removeFileEntry(file.uid));
			message.success(`${file.filename} has been deleted successfully`);
		}
	};

	const handleIconRender = (file) => {
		const filesIcons = {
			image: <FileImageOutlined />,
			video: <VideoCameraOutlined />,
			audio: <SoundOutlined />,
		};

		const fileType =
			file['type'] && file['type'].substring(0, file['type'].indexOf('/'));

		if (fileType in filesIcons) return filesIcons[fileType];
		else return <FileOutlined />;
	};

	const antUploadProps = {
		name: 'file',
		multiple: parseInt(maxFileCount) > 1,
		maxCount: parseInt(maxFileCount),
		beforeUpload: handleBeforeUpload,
		onChange: handleOnChange,
		onPreview: handleOnPreview,
		iconRender: handleIconRender,
		openFileDialogOnClick:
			!isUploadBtnDisabled &&
			!toUploadCompletionFiles[activeCompletionID]?.isReviewer,
	};

	if (user.role !== ROLE_PROJECT_MANAGER) {
		antUploadProps.fileList = attachedFiles;
		antUploadProps.onRemove = handleOnDelete;
	}

	if (
		toUploadCompletionFiles[activeCompletionID] &&
		toUploadCompletionFiles[activeCompletionID]?.isReviewer
	) {
		antUploadProps.showUploadList = {
			showRemoveIcon: false,
		};
	}

	const Preview = ({ data }) => {
		const isVideo = data.type.includes('video');
		const isAudio = data.type.includes('audio');
		const isImage = data.type.includes('image');
		const isPdf = data.type.includes('pdf');
		const isHtml = data.type.includes('htm');
		const isEml = data.type.includes('message/rfc822');
		const isText = data.type.includes('text') && !isHtml && !isEml;

		return (
			<div style={{ height: '100%', width: '100%' }}>
				{data.error && <ErrorAlert error={data.error} />}
				{!data.error && data.file && (
					<div style={{ height: '100%', width: '100%' }}>
						{isImage && <ImageViewer file={data.file} url={data.url} />}
						{isAudio && (
							<AudioViewer
								file={data.file}
								url={data.url}
								togglePlay={handlePlay}
							/>
						)}
						{isVideo && (
							<VideoViewer
								file={data.file}
								url={data.url}
								togglePlay={handlePlay}
							/>
						)}
						{isPdf && <PdfViewer file={data.file} url={data.url} />}
						{isHtml && <HtmlViewer file={data.file} url={data.url} />}
						{isText && <TextViewer file={data.file} url={data.url} />}
						{isEml && <EmlViewer file={data.file} url={data.url} />}
						{!isImage &&
							!isAudio &&
							!isVideo &&
							!isHtml &&
							!isEml &&
							!isText && <OthersViewer file={data.file} url={data.url} />}
					</div>
				)}
			</div>
		);
	};

	const handleRenderFiles = () => {
		if (user.role !== ROLE_PROJECT_MANAGER) {
			const files = antUploadProps.fileList.map((file) => {
				const displayedFileOrder = file.order ? file.order : '';
				const displayedFileName = file.filename ? file.filename : file.name;
				const displayedDate = file.uploadedAt ? file.uploadedAt : '';

				let fileStatusIcon;

				switch (file.status) {
					case UPLOAD_PROCESS_STATUS_CODES.NotStarted:
						fileStatusIcon = <UploadOutlined title={'Pending'} />;
						break;
					case UPLOAD_STATES.Uploading:
						fileStatusIcon = (
							<Progress
								percent={file.progress}
								size="small"
								type={'circle'}
								width={15}
							/>
						);
						break;
					case UPLOAD_STATES.Validating:
						fileStatusIcon = (
							<PauseCircleTwoTone
								title="Validating..."
								twoToneColor="#FFA500"
							/>
						);
						break;
					case UPLOAD_STATES.Invalid:
						fileStatusIcon = (
							<ExclamationCircleTwoTone
								title="Invalid"
								twoToneColor="#FF2500"
							/>
						);
						break;
					case UPLOAD_STATES.Finished:
						if(file?.isValid){
							fileStatusIcon = (
								<CheckCircleTwoTone
									title="Upload Complete"
									twoToneColor="#52c41a"
								/>
							);
						} else
						{
							fileStatusIcon = (
								<ExclamationCircleTwoTone
									title="Error"
									twoToneColor="#FFA500"
								/>
							);
						}
						break;
					case UPLOAD_STATES.Failed:
						fileStatusIcon = (
							<ExclamationCircleTwoTone
								title="Error..."
								twoToneColor="#FFA500"
							/>
						);
						break;
					default:
							fileStatusIcon = <UploadOutlined title={'Pending'} />;
						break;
				}

				const formatFileName = () =>
					displayedFileName.length > 30
						? displayedFileName.substring(0, 30) + '...'
						: displayedFileName;

				return {
					...file,
					filename: file.filename || file.name,
					name: isMobile ? (
						`${displayedFileOrder}  ${displayedDate}  ${displayedFileName}`
					) : (
						<Row title={displayedFileName} style={{ gap: '8px' }}>
							{fileStatusIcon && <Col>{fileStatusIcon}</Col>}
							{displayedFileOrder && <Col>{displayedFileOrder}</Col>}
							{displayedDate && <Col>{displayedDate}</Col>}
							{displayedFileName && (
								<Col span={9}>
									{displayedFileOrder ? formatFileName() : displayedFileName}
								</Col>
							)}
						</Row>
					),
				};
			});

			return files;
		}
	};

	const uploadMobileStyles = isMobile && {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
	};

	return (
		<Fragment>
			<Space direction="vertical" className="upload-container">
				{!acceptedExtensionsError && !hasValidationError && (
					<Space className="upload-element">
						<AntUpload {...antUploadProps} fileList={handleRenderFiles()}>
							<Space style={uploadMobileStyles}>
								<Tooltip
									title={
										isUploadBtnDisabled &&
										`You cannot upload more than ${parseInt(
											maxFileCount,
										)} file(s)`
									}
								>
									<Button
										icon={<UploadOutlined />}
										type="primary"
										disabled={isUploadBtnDisabled}
										style={{
											display:
												toUploadCompletionFiles[activeCompletionID]
													?.isReviewer && 'none',
										}}
									>
										Click to Upload
									</Button>
								</Tooltip>

								<Text
									className="upload-minFiles"
									style={
										toUploadCompletionFiles[activeCompletionID]?.isReviewer && {
											paddingRight: '10rem',
										}
									}
								>
									Min {parseInt(minFileCount)} file(s) required / Max{' '}
									{parseInt(maxFileCount)} file(s)
								</Text>
							</Space>
						</AntUpload>
						<Drawer
							placement="right"
							onClose={handleOnClose}
							visible={isDrawerOpen}
							width={isMobile || isTablet ? '100%' : '50%'}
							onContextMenu={(e) => e.preventDefault()}
						>
							{fileData && <Preview data={fileData} />}
						</Drawer>
					</Space>
				)}

				{hasValidationError && (
					<ErrorAlert
						error={{
							title: 'Validation Error',
							details: validationResult.errors,
						}}
					/>
				)}

				{acceptedExtensionsError && (
					<ErrorAlert error={acceptedExtensionsError} />
				)}
			</Space>
		</Fragment>
	);
};

export default Upload;
